import { render, staticRenderFns } from "./Precarite.vue?vue&type=template&id=7fd761fa&scoped=true&"
import script from "./Precarite.vue?vue&type=script&lang=js&"
export * from "./Precarite.vue?vue&type=script&lang=js&"
import style0 from "./Precarite.vue?vue&type=style&index=0&id=7fd761fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fd761fa",
  null
  
)

export default component.exports