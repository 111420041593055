<template>
  <div class="editable-input-precarite">
    <div class="edit-view" v-if="editing">
      <input
        class="input-style"
        :type="type"
        v-model="newValue"
        @keyup.enter="update"
        @keyup.esc="editing = false"
        ref="autofocus"
      />
      <div class="edit-action-input  justify-content-end mt-1">
        <div @click="resetInput">Annuler</div>
        <div @click="update" class="green ml-2">Enregistrer</div>
      </div>
    </div>
    <div class="show-view" v-else @click="editing = true">
      <div class="value">
        {{ value | format }} €
        <font-awesome-icon class="show-view-pencil ml-2" icon="pencil-alt" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditableInputPrecarite',
  props: {
    field: { required: true },
    entity: { required: true },
    value: { required: true },
    type: { default: 'number' },
    updateFunction: { required: true }
  },
  data() {
    return {
      editing: false,
      newValue: this.value
    }
  },
  methods: {
    resetInput() {
      this.newValue = this.value
      this.editing = false
    },
    async update() {
      if (this.value != this.newValue) {
        const key = this.field == 'IDF' ? 'revenus_idf' : 'revenus_province'
        await this.updateFunction({
          entity: this.entity,
          data: {
            [key]:
              Math.round((parseFloat(this.newValue) + Number.EPSILON) * 100) /
              100
          }
        })
        this.newValue = this.value
      }
      this.editing = false
    }
  },
  watch: {
    editing(value) {
      if (value) {
        this.$nextTick(() => {
          if (this.$refs.autofocus) {
            this.$refs.autofocus.focus()
          }
        })
      }
    }
  },
  filters: {
    format: function(value) {
      if (!value) {
        return 0
      }
      return value
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        .replace(/\./g, ',')
    }
  }
}
</script>

<style lang="scss" scoped>
.editable-input-precarite {
  .edit-view {
    display: inline-block;
    .input-style {
      text-align: center;
    }
    .edit-action-input {
      display: flex;

      div {
        cursor: pointer;
        color: #5e5e5e;
        border: 1px solid #a9adb1;
        user-select: none;
        margin: 0;
        padding: 0px 4px;
        border-radius: 2px;
        font-size: 11px;
        font-weight: 600;
        &.green {
          background: #08a742;
          color: #fff;
        }
      }
    }
  }
  .show-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .show-view-pencil {
      color: #9e9e9e;
      opacity: 0;
      display: none;
      transition: opacity 0.2s ease;
    }
    &:hover {
      .show-view-pencil {
        display: inline-block;
        opacity: 1;
      }
    }
  }
}
</style>
