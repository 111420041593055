<template>
  <div class="page-content-precarite">
    <div class="header">
      <div class="titre">
        <h4>Gestion des Précarités</h4>
      </div>
      <div
        v-if="getPrecariteLoading || getPrecariteUpdateLoading"
        class="three-dots-loading ml-3 mb-1"
      >
        Chargement en cours
      </div>
    </div>
    <div v-if="getPrecariteError" class="error">
      <ul v-if="Array.isArray(getPrecariteError)">
        <li v-for="(e, index) in getPrecariteError" :key="index">
          {{ e }}
        </li>
      </ul>
      <span v-else>{{ getPrecariteError }}</span>
    </div>
    <hr class="line" />
    <div class="precarite">
      <div class="filter-annee">
        <h4 class="mr-3 mb-1">Plafond des revenus</h4>
        <div class="select-year-precarite">
          <multiselect
            v-model="selectedYear"
            :options="['2021', '2022']"
            placeholder="Année"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :preselect-first="false"
            :max-height="100"
            :allow-empty="false"
            deselectLabel=""
            selectLabel=""
            @input="changeYear(selectedYear)"
          >
            <span slot="noResult">Aucun Catégorie trouvé.</span>
            <span slot="noOptions">Aucun Catégorie trouvé.</span>
          </multiselect>
        </div>
      </div>
      <b-table-simple class="precarite-table" v-if="precarite" small striped>
        <!-- table -->
        <b-thead>
          <b-tr class="header-tr">
            <b-th>Nombre de personnes composant le ménage</b-th>
            <b-th>Plafonds de revenus du ménage en Île-de-France (€)</b-th>
            <b-th
              >Plafonds de revenus du ménage pour les autres régions (€)</b-th
            >
          </b-tr>
        </b-thead>
        <template v-for="table in precarite">
          <b-tbody :key="'pb-' + table.title">
            <b-tr class="sub-header-tr">
              <b-th class="border-none"></b-th>
              <b-th class="border-none">{{ table.title }}</b-th>
              <b-th class="border-none"></b-th>
            </b-tr>
            <b-tr v-for="item in table.data" :key="'pv-' + item.id">
              <b-th>
                <div>{{ item.nbr_personne }}</div>
              </b-th>
              <b-td
                :class="{ 'three-dots-loading content': getPrecariteLoading }"
              >
                <EditableInputPrecarite
                  v-if="!getPrecariteLoading"
                  :entity="item"
                  type="number"
                  :value="item.revenus_idf"
                  :updateFunction="updatePrecarite"
                  :field="'IDF'"
                />
              </b-td>
              <b-td
                :class="{ 'three-dots-loading content': getPrecariteLoading }"
              >
                <EditableInputPrecarite
                  v-if="!getPrecariteLoading"
                  :entity="item"
                  type="number"
                  :value="item.revenus_province"
                  :updateFunction="updatePrecarite"
                  :field="'Province'"
                />
              </b-td>
            </b-tr>
          </b-tbody>
        </template>
      </b-table-simple>
      <h4>Calcul coefficient</h4>
      <div v-if="getPrecariteLoading" class="three-dots-loading ml-3 mb-1">
        Chargement en cours
      </div>
      <b-table-simple
        v-if="selectedYear == '2021' && !getPrecariteLoading"
        class="precarite-table"
        small
        striped
      >
        <b-thead>
          <b-tr class="header-tr">
            <b-th>Coeff</b-th>
            <b-th>Elec</b-th>
            <b-th>Gaz</b-th>
            <b-th>Fioul</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr class="" v-for="item in getPrecariteCumac" :key="item.id">
            <b-th class="color-th">{{ item.type_coef }} </b-th>
            <b-td>{{ item.elec }}</b-td>
            <b-td>
              {{ item.gaz }}
              <!-- <EditableInputPrecarite
                  :entity="item"
                  type="number"
                  :value="item.revenus_idf"
                  :updateFunction="updatePrecarite"
                  :field="'IDF'"
                /> -->
            </b-td>
            <b-td>
              {{ item.fioul }}
              <!-- <EditableInputPrecarite
                  :entity="item"
                  type="number"
                  :value="item.revenus_province"
                  :updateFunction="updatePrecarite"
                  :field="'Province'"
                /> -->
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-table-simple
        v-if="selectedYear == '2022' && !getPrecariteLoading"
        class="precarite-table"
        small
        striped
      >
        <b-thead>
          <b-tr class="header-tr">
            <b-th>Coeff B</b-th>
            <b-th>Si cep projet ≤ 110</b-th>
            <b-th>Si cep projet > 110</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr class=" " v-for="item in getPrecariteCumac" :key="item.id">
            <b-th class="color-th">{{ item.type_coef }} </b-th>
            <b-td>
              {{ item.cep_inf_110 }}
              <!-- <EditableInputPrecarite
                  :entity="item"
                  type="number"
                  :value="item.revenus_idf"
                  :updateFunction="updatePrecarite"
                  :field="'IDF'"
                /> -->
            </b-td>
            <b-td>
              {{ item.cep_sup_110 }}
              <!-- <EditableInputPrecarite
                  :entity="item"
                  type="number"
                  :value="item.revenus_province"
                  :updateFunction="updatePrecarite"
                  :field="'Province'"
                /> -->
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EditableInputPrecarite from '@/components/ui/EditableInputPrecarite'

export default {
  name: 'Precarite',
  components: {
    EditableInputPrecarite
  },
  data() {
    return {
      selectedYear: '2021'
    }
  },
  methods: {
    ...mapActions(['updatePrecarite', 'fetchPrecarite', 'fetchPrecariteCumac']),
    async changeYear() {
      await this.fetchPrecarite(this.selectedYear)
      this.fetchPrecariteCumac({
        route: '/api/cumac-' + this.selectedYear,
        year: this.selectedYear
      })
    }
  },
  computed: {
    ...mapGetters([
      'getPrecarite',
      'getPrecariteLoading',
      'getPrecariteError',
      'getPrecariteCumac',
      'getPrecariteUpdateLoading'
    ]),
    precarite: function() {
      return [
        {
          title: 'PRÉCARITÉ',
          data: this.getPrecarite.filter(p => p.type == 'Precarite')
        },
        {
          title: 'GRANDE PRÉCARITÉ',
          data: this.getPrecarite.filter(p => p.type == 'Grand Precarite')
        }
      ]
    }
  },
  async mounted() {
    await this.fetchPrecarite(this.selectedYear)
    this.fetchPrecariteCumac({
      route: '/api/cumac-' + this.selectedYear,
      year: this.selectedYear
    })
  }
}
</script>
<style lang="scss" scoped>
.page-content-precarite {
  .header {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .filter-annee {
    display: flex;
    align-items: center;
    .select-year-precarite {
      width: 27%;
      margin-bottom: 10px;
    }
  }
  .error {
    color: red;
  }

  .precarite {
    .precarite-table {
      text-align: center;
      font-size: 12px;
      .border-none {
        border: none;
      }
      th,
      td,
      tr {
        border: 1px solid #dee2e6;
      }
      .header-tr {
        height: 45px;
        th {
          font-size: 14px;
          vertical-align: middle;
        }
      }
      .color-th {
        background: #404346;
        color: #fff;
        width: 30%;
        font-size: 14px;
        text-transform: uppercase;
      }
      .sub-header-tr {
        th {
          background: #404346;
          color: #fff;
          font-size: 14px;
          text-transform: uppercase;
        }
      }
      .content {
        content: '...';
        color: #404346;
      }
    }
  }
}
</style>
